<template>
  <div class="ml-5 mr-5">

    <div class="text-left">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
      <v-container fluid>
        <v-row>
          <v-col cols="3">
          <v-select
          v-model="filterName"
          :items="items"
          menu-props="auto"
          label="Select Search By"
          hide-details
          prepend-icon="mdi-account"
          single-line
          @change="resetfilterValue"
        ></v-select>
            <!-- <v-select
              :items="items"
              :menu-props="{ top: true, offsetY: true }"
              label="Select Search By"
              v-model="filterName"
            ></v-select> -->
          </v-col>
          <v-col cols="4">
          <div v-if="filterName !== 'Account status' && filterName !== 'Role'">
              <v-text-field
                v-model="filterValue"
                label="Type your search"
                 >
           </v-text-field>
            </div>
             <div v-if="filterName === 'Account status'">
          <v-select
          v-model="filterValue"
          :items="status"
          menu-props="auto"
          hide-details
          single-line
        ></v-select>
            </div>
        <div v-if="filterName === 'Role'">
          <v-autocomplete
            v-model="filterValue"
            :disabled="!isRoleDdEditing"
            :items="roles"
            clearable
            item-text="key"
            item-value="value"
            :label= role_label
          ></v-autocomplete>
        </div>
          </v-col>
          <v-col cols="2" class="my-4" >
          <v-btn small class="confirm_button" text @click="filterUser">Search</v-btn>
          <v-btn small class="default ml-3"  @click="resetSearch()">
              Reset
            </v-btn>
           </v-col>
          <v-col cols="3" class="my-4 text-right" >
            <template>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small color="primary mr-3" dark v-bind="attrs" v-on="on">
              Add User
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add User</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit="addUserData"
              method="post"
              v-model="valid"
              lazy-validation
            >
              <v-container class="px-50  v-card-padding" fluid>
                <v-text-field
                  v-model="addUser.userName"
                  label="User Name"
                  autocomplete="off"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="addUser.userEmail"
                  :rules="emailRules"
                  autocomplete="off"
                  label="Email Address"
                ></v-text-field>
                  <v-text-field
                  v-model="addUser.password"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  label="Enter Password"
                  autocomplete="off"
                  :rules="[temprules.password]"
                   hint="Min. 14 characters(e.g:uv$yrde34U4RX5f>)"
                  @click:append="show2 = !show2"
                  required
                ></v-text-field>
                <div style="color: red">
                  {{ message1 }}
                </div>
                <!-- <v-text-field
                  v-model="addUser.confirm_password"
                  label="Confirm Password"
                  name="confirmPassword"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show3 ? 'text' : 'password'"
                   @click:append="show3 = !show3"
                  :rules="confirmPasswordRules"
                  /> -->
              </v-container>
              <v-container class="px-50" fluid> </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" type="submit" @click="validate">
                  Submit
                </v-btn>
                <v-btn class="close_button mx-2" text @click="reset"> Close </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
      <v-btn small color="warning" @click="syncUsers()">
              Sync Users
            </v-btn>
           </v-col>
        </v-row>
      </v-container>
    </div>
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :page.sync="page"
              :items="user_data"
              :options.sync="options"
              :server-items-length="total_users"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
              @page-count="pageCount = $event"
            >
            <template v-slot:item.actions="{ item }">
               {{item.name}}
               <div v-if="item.role !== null">
                <v-dialog
                  persistent
                   v-model="dialog3"
                  :retain-focus="false"
                  max-width="600px"
                >
                  <router-link
                :to="{
                path: 'user-log/' + item.name,
                }"
                ><div color="primary" dark small v-on="on">
                   view log
                </div>
                </router-link >
                </v-dialog>
                </div>
              </template>
              <template v-slot:item.action1="{ item }">
                <v-icon
                      medium
                      color="grey"
                      v-bind="attrs"
                      title="Change Password"
                      v-on="on"
                      @click="addToUpdate($event, item.name, item.role),dialog2 = true"
                    >
                      mdi-key
                </v-icon>
                <v-icon
                      medium
                      color="grey"
                      v-bind="attrs"
                      class="ml-2"
                      title="Assign Role"
                      v-on="on"
                      @click="addToUpdate($event, item.name, item.role_id),dialog1 = true"
                    >
                      mdi-account-circle
                    </v-icon>
                 <template>
              </template>
            </template>
            <template v-slot:item.action2="{ item }">
              <v-switch
                  v-model="item.Enabled"
                  inset
                  dense
                  @click="openUserStatusModal($event, item.Enabled, item.name)"
                ></v-switch>
            </template>
            </v-data-table>
            <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>

            <div>
          </div>
        </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- v-data-table-ends -->
    <v-dialog
      v-model="dialog1"
      persistent
      :retain-focus="false"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Update User Role</span>
        </v-card-title>
        <v-form
          ref="form"
          @submit="updateUser"
          method="put"
        >
          <v-container class="px-50" fluid>
            {{updateUser.userName}}
            <div style="color: red">
              {{ upmessage1 }}
            </div>
            <v-select
              v-model="updateUser.userRole"
              :disabled="!isRoleDdEditing"
              :items="updateRoles"
              clearable
              item-text="key"
              item-value="value"
              label="Select Role"
            ></v-select>
            <div style="color: red">
              {{ message1 }}
            </div>
          </v-container>
          <v-container class="px-50" fluid>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              @click="AssignRole"
              type="submit"
            >
              Update
            </v-btn>
            <v-btn class="close_button mx-2" text @click="reset">
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog2"
      persistent
      :retain-focus="false"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Update Password</span>
        </v-card-title>
        <v-form
          ref="validUpdatePassForm"
          v-model="validUpdatePassForm"
        >
          <v-container class="px-50" fluid>
              <v-text-field
              v-model="updateUserPass.userPassword"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              autocomplete="off"
              name="input-10-1"
              :rules="[rules.password]"
              hint="Min. 14 characters(e.g:uv$yrde34U4RX5f>)"
              counter
              @click:append="show1 = !show1"
              label="New Password"
              required
            ></v-text-field>
            <div style="color: red">
              {{ password_error }}
            </div>
          </v-container>
          <v-container class="px-50" fluid>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              @click="updatePassword($event)"
            >
              Update
            </v-btn>
            <v-btn class="close_button mx-2" text @click="reset">
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="toggleUserUpdateModel" persistent max-width="600px" :retain-focus="false">
                              <v-card>
              <v-card-text>
                <div class="text-h3 pa-12">
                  Are you sure you want to {{enable_or_disable}} the selected User
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                  <v-btn
                    text
                    class="confirm_button mx-2"
                    v-on:click="dialog4 = false"
                   @click="updateUserStatus($event, toBeUpdateUser, enable_or_disable)"
                    >Yes</v-btn
                  >
                </v-form>
                <v-btn class="close_button mx-2" text @click="locallyUpdateUserStatus(toBeUpdateUser)">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
</v-dialog>


  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";

export default {
  components: { Index, Buttons, Loading, Pagination },
  data() {
    return {
      //////table data starts here////
      validUpdatePassForm:true,
      perpage: 50,
      totRecords: null,
      page: 1,
      selected: [],
      options: {},
      sort: "",
      affinity_data: [],
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        // {
        //   text: "",
        //   value: "selectCheckbox",
        //   width: "50px",
        //   sortable: false,
        //   class: "v-data-table-header",
        // },
        {
          text: "Username",
          value: "actions",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Role",
          value: "role",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Account Status",
          value: "account_status",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
        // {
        //   text: "Verified",
        //   value: "action3",
        //   sortable: false,
        //   class: "v-data-table-header",
        // },
        {
          text: "Email",
          value: "email",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Updated",
          value: "UserLastModifiedDate",
          sortable: false,
          width: "100px",
          class: "v-data-table-header",
        },
        {
          text: "Actions",
          value: "action1",
          width: "100px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Status",
          value: "action2",
          width: "100px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      toggleUserUpdateModel: false,
      enable_or_disable: "",
      toBeUpdateUser: null,
      valid: true,
      filterValue: null,
      attrs: "",
      on: "",
      checkbox: false,
      isLoading: true,
      fullPage: true,
      userDetails: [],
      dialog: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      deleteItems: [],
      checked_audienceId: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      errorPass: "",
      verified: true,
      allSelected: false,
      user_data: [],
      total_users: 0,
      roles: [],
      updateRoles: [],
      role_label: "Loading...",
      isRoleDdEditing: false,
      password_error: null,
      showPerPage: false,
      addUser: {
        userName: null,
        userEmail: null,
        password: null,
        confirm_password: null,
      },
      updateUser: {
        userName: null,
        userRole: null,
      },
      updateUserPass: {
        userName: null,
        userPassword: null,
      },
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      show1: false,
      show2: false,
      show3: false,
      passwordRules: [
        (value) => !!value || "Please type password.",
        (value) => (value && value.length >= 14) || "minimum 14 characters",
      ],
      confirmPasswordRules: [
        (value) => !!value || "type confirm password",
        (value) =>
          value === this.password ||
          "The password confirmation does not match.",
      ],
      rules: {
        password: (value) => {
          const pattern =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{14,})/;
          return (
            pattern.test(value) ||
            "Min. 14 characters with at least one capital letter, a number and a special character(e.g:uv$yrde34U4RX5f>)."
          );
        },
      },
      temprules: {
        password: (value) => {
          const pattern =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{14,})/;
          return (
            pattern.test(value) ||
            "Min. 14 characters with at least one capital letter, a number and a special character(e.g:uv$yrde34U4RX5f>)."
          );
        },
      },
      addUserlabel: null,
      items: ["Username", "Email", "Account status", "Role"],
      status: ["CONFIRMED", "FORCE_CHANGE_PASSWORD", "RESET_REQUIRED"],
      filterName: "Username",
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getUsers(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getUsers(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getUsers(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getUsers(this.page);
    },
    validate() {
      this.$refs.form.validate();
    },
    resetfilterValue() {
      this.filterValue = "";
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.dialog2 = false;
      this.dialog4 = false;
      //this.filterValue = "";
      this.toggleUserUpdateModel = false;
      this.message1 = "";
      this.upmessage1 = "";
      this.errorPass = "";
      this.password_error = "";
      this.updateUser.userPassword = null;
      this.$refs.form.reset();
    },
    resetSearch() {
      if (this.filterValue != null) {
        (this.page = 1),
          (this.filterName = "Username"),
          (this.filterValue = null);
          this.$utils.setCookies('manage_users_username', 'Username', this)
          this.$utils.setCookies('manage_users_filterValue', '', this)
          this.getUsers();
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    getUsers(page = "", filterName = "", filterValue = "") {
      let check = this;
      let filterType,finalfilterValue


      this.filterName = this.$utils.getCookies('manage_users_username') && this.$utils.getCookies('manage_users_username') !='' ? this.$utils.getCookies('manage_users_username') : 'Username'

      if(this.filterName == 'Account status'){
        filterType = "cognito:user_status";
      }else{
        filterType = this.filterName;
      }

      if(filterType == 'Role'){
        finalfilterValue = this.$utils.getCookies('manage_users_filterValue') && this.$utils.getCookies('manage_users_filterValue') != '' ? parseInt(this.$utils.getCookies('manage_users_filterValue')) : ''
      }else{
        finalfilterValue = this.$utils.getCookies('manage_users_filterValue') && this.$utils.getCookies('manage_users_filterValue') != '' ? this.$utils.getCookies('manage_users_filterValue') : ''
      }
      this.filterValue = finalfilterValue
      //this.$url("MANAGEUSERS")
      //http://0.0.0.0:5056/manageUsers
      let page_no = page == '' ? this.page : page
      const path = this.$url("MANAGEUSERS")+"?fname="+filterType +"&fvalue="+finalfilterValue +"&page="+page_no;
      check.isLoading = true;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res);

          this.userDetails = res.data.payload.users;

          console.log(user);

          let user_role = res.data.payload[1];
          check.total_users = res.data.payload.total_users;
          if(check.totRecords != res.data.payload.total_users){
            check.page = 1
          }
          check.totRecords = res.data.payload.total_users;

          let user = res.data.payload.users;
          if (Array.isArray(user) && user.length) {
            this.user_data = [];
            for (let i = 0; i < user.length; i++) {
              let formaated_date = this.format_date(
                user[i].modified_date
              );
              let tempobj = {
                user_id: user[i].user_id,
                name: user[i].user_name,
                // emailVerified: user[i].emailVerified,
                emailVerified: "no",
                email: user[i].user_email,
                UserLastModifiedDate: formaated_date,
                Enabled: user[i].user_status == 1 ? true : false,
                account_status: user[i].cognito_account_status,
                role_id: user[i].role_id,
                role: user[i].role_name,
              };
              this.user_data.push(tempobj);
            }
            // user.forEach(function (userd, i) {
            //   user_role.forEach(function (urole, j) {
            //     if (userd.name == urole[1]) {
            //       console.log(i);
            //       console.log(userd.name, urole[1]);
            //       check.user_data[i].role = urole[3];
            //       console.log(check.user_data[i].role, userd.name);
            //     }
            //   });
            // });

            const path = this.$url("USERROLE");
            this.$fetch({
              requiresAuth: true,
              operation: "view",
              vueScope: check,
            })
              .get(path)
              .then((res) => {
                console.log(res);
                this.roleName = res.data.payload.roleId[0];
                if (this.roleName.length) {
                  // let a = {
                  //     key: "--Select Option--",
                  //     value: -1,
                  //   };
                  // check.roles.push(a);
                  check.roles = [];
                  check.updateRoles = []
                  this.roleName.forEach(function (item) {
                    let a = {
                      key: item[1],
                      value: item[0],
                    };
                    check.roles.push(a);
                    if(a.value!=1){
                      check.updateRoles.push(a)
                    }
                    // this.user_roles.push(a)
                  });
                  check.isRoleDdEditing = true;
                  check.role_label = "Select Role"
                }
              });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    syncUsers() {
      // SYNC_DB_USERS
      let check = this;
      const path = this.$url("SYNC_DB_USERS");
      //const path  = "http://0.0.0.0:5056/syncDbUsers"
      check.isLoading = true;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res);
          if (res.data.payload.success) {
            this.$swal.fire({
              icon: "success",
              title: "Success",
              text: "Users synced successfully!",
            });
          } else {
            this.$swal.fire({
              icon: "Error",
              title: "Error",
              text: res.data.payload.error,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addUserData(e) {
      e.preventDefault();
      let view = this;
      console.log(this.addUser.userName);
      if (this.addUser.userName != "" && this.addUser.userName != null) {
        this.$fetch({ requiresAuth: true, operation: "add", vueScope: view })
          .post(this.$url("MANAGEUSERS"), this.addUser)
          .then((result) => {
            console.log(result.data.payload);
            view.message1 = result.data.payload[0].response;
            view.message2 = result.data.payload[0].responseStatus;
            if (this.message2 == null) {
              this.message1 = result.data.payload[0].response;
              this.dialog = true;
            } else {
              this.message1 = null;
              this.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.$swal.fire({
                  icon: "success",
                  title: "User Added",
                  text: result.data.payload.message,
                });
                this.addUser = {
                  userName: null,
                  userEmail: null,
                  password: null,
                  confirm_password: null,
                }
              }
              this.getUsers();
              this.reset();
            }
            console.log(result);
          });
      }
    },
    AssignRole(e) {
      e.preventDefault();
      let view = this;
      console.log(view.updateUser);
      //this.$url("ENABLEUSER")
      view.isLoading = true;
      // if (view.updateUser.userRole != "" && view.updateUser.userRole != null) {
        this.$fetch({ requiresAuth: true, operation: "add", vueScope: view })
          .post(this.$url("ENABLEUSER"), this.updateUser)
          .then((result) => {
            view.isLoading = false;
            console.log(result.data.payload);
            this.message1 = result.data.payload[0].response;
            this.message2 = result.data.payload[0].responseStatus;
            if (this.message2 == null) {
              this.message1 = result.data.payload[0].response;
              this.dialog1 = true;
            } else {
              this.message1 = null;
              this.dialog1 = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.$swal.fire({
                  icon: "success",
                  title: "Role Assigned",
                  text: result.data.payload.message,
                });
              }
              this.getUsers();
              this.reset();
            }
            console.log(result);
          });
    },

    updateUserStatus(e, user, enable_or_disable) {
      if (enable_or_disable == "enable") {
        this.enableUser(e, user);
      } else {
        this.disableUser(e, user);
      }
    },
    openUserStatusModal(e, isEnabled, username) {
      console.log(isEnabled);
      if (isEnabled == false) {
        this.enable_or_disable = "disable";
      } else {
        this.enable_or_disable = "enable";
      }
      this.toBeUpdateUser = username;
      this.toggleUserUpdateModel = true;
    },
    locallyUpdateUserStatus(toBeUpdateUser){
      this.toggleUserUpdateModel = false
      this.user_data.forEach((user)=>{
        if(user.name == toBeUpdateUser){
          user.Enabled = !user.Enabled
        }
      })
    },
    disableUser(e, user) {
      e.preventDefault();
      let data = this;
      data.isLoading = true;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: data })
        .get(data.$url("RESETPASSWORD") + "?name=" + user)
        .then((result) => {
          data.isLoading = false;
          data.enable_or_disable = "";
          data.toggleUserUpdateModel = false;
          if (result.data.statusCode == 200) {
            this.$swal.fire({
              icon: "success",
              title: "User is disabled",
              text: result.data.payload.message,
            });
          }
          this.getUsers();
          this.reset();
          console.log(result);
        });
    },
    enableUser(e, user) {
      e.preventDefault();
      let data = this;
      data.isLoading = true;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: data })
        .get(data.$url("ENABLEUSER") + "?name=" + user)
        .then((result) => {
          data.isLoading = false;
          data.enable_or_disable = "";
          data.toggleUserUpdateModel = false;
          if (result.data.statusCode == 200) {
            this.$swal.fire({
              icon: "success",
              title: "User is enabled",
              text: result.data.payload.message,
            });
          }
          this.reset();
          this.getUsers();
          console.log(result);
        });
    },
    addToUpdate(e, user, userrole) {
      let view = this;
      view.updateUser.userName = user;
      view.updateUserPass.userName = user;
      // view.roles.forEach(function (role, i) {
      //   if (userrole == role.text) {
      view.updateUser.userRole = userrole;
      //     console.log(view.updateUser.userRole);
      //   }
      // });
    },
    updatePassword(e) {
      e.preventDefault();
      if(this.$refs.validUpdatePassForm.validate()){
        let data = this;
        if (
          data.updateUserPass.userPassword != "" &&
          data.updateUserPass.userPassword.length >= 8
        ){
          data.isLoading = true;
          console.log(data.updateUserPass.userPassword);
          //data.$url("MANAGEUSERS")
          this.$fetch({ requiresAuth: true, operation: "edit", vueScope: data })
            .put(data.$url("MANAGEUSERS"), data.updateUserPass)
            .then((result) => {
              data.isLoading = false;
              this.upmessage1 = result.data.payload[0].response;
              this.upmessage2 = result.data.payload[0].responseStatus;
              console.log(result.data.payload[0].response);
              if (this.upmessage2 == null) {
                this.upmessage1 = result.data.payload[0].response;
                this.dialog2 = true;
              } else {
                this.upmessage1 = null;
                this.dialog2 = false;
                if (result.data.statusCode == 200 && this.upmessage1 == null) {
                  this.$swal.fire({
                    icon: "success",
                    title: "Password Updated",
                    text: result.data.payload.message,
                  });
                  data.updateUserPass = {
                    userName: null,
                    userPassword: null,
                  }
                }
                this.getUsers();
                this.reset();
              }

              console.log(result);
            });
        } else {
          dialog2 = true;
          this.password_error =
            "Min 14 characters requiring uppercase, numeric, and special characters";
        }
      }
    },
    // updateUserPass(e) {
    //   e.preventDefault();
    //   let data = this;
    //   if (data.updateUser.userPassword == data.updateUser.confirmPassword) {
    //     if (
    //       data.updateUser.userPassword != "" &&
    //       data.updateUser.userPassword.length >= 8
    //     ) {
    //       this.$fetch({ requiresAuth: true, operation : 'edit', vueScope: data })
    //         .put(data.$url("MANAGEUSERS"), data.updateUser)
    //         .then((result) => {
    //           this.upmessage1 = result.data.payload[0].response;
    //           this.upmessage2 = result.data.payload[0].responseStatus;
    //           console.log(result.data.payload[0].response);
    //           if (this.upmessage2 == null) {
    //             this.upmessage1 = result.data.payload[0].response;
    //             this.dialog1 = true;
    //           } else {
    //             this.upmessage1 = null;
    //             this.dialog1 = false;
    //             if (result.data.statusCode == 200 && this.upmessage1 == null) {
    //               this.$swal.fire({
    //                 icon: "success",
    //                 title: "Password Updated",
    //                 text: result.data.payload.message,
    //               });
    //             }
    //             this.getUsers();
    //             this.reset();
    //           }

    //           console.log(result);
    //         });
    //     }
    //   } else {
    //     this.errorPass = "both passwords are not matching";
    //   }
    // },
    // addToDelete(e, atchid) {
    //   this.checkedthis = false;
    //   if (e.target.checked) {
    //     if (this.checked_audienceId.indexOf(e.target.value) == -1) {
    //       this.checked_audienceId.push(e.target.value);
    //     }
    //   } else {
    //     this.checked_audienceId.splice(
    //       this.checked_audienceId.indexOf(e.target.value),
    //       1
    //     );
    //   }
    //   let checkcedstr = this.checked_audienceId.join(",");
    //   this.checkcedstr = checkcedstr;
    //   console.log(this.checkcedstr);
    // },
    // deleteUser(e) {
    //   e.preventDefault();
    //   let view = this
    //   console.log(this.deleteItems);
    //   this.$fetch({ requiresAuth: true, operation : 'delete', vueScope: view })
    //     .delete(this.$url(""), {
    //       params: { id: this.deleteItems },
    //     })
    //     .then((response) => {
    //       if (response.data.statusCode == 200) {
    //         this.$swal.fire({
    //           icon: "success",
    //           title: "User Deleted",
    //           text: response.data.payload.message,
    //         });
    //       }
    //       this.getUsers();
    //       this.deleteItems = [];
    //     });
    // },
    selectAll: function () {
      let users = this.user_data;
      if (this.allSelected == true) {
        if (Array.isArray(users) && users.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < users.length; i++) {
            console.log(users[i]);
            this.deleteItems.push(users[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
    filterUser: function () {
      let filterType = "";
      if (this.filterName == "Account status") {
        filterType = "cognito:user_status";
      } else {
        filterType = this.filterName;
      }
      this.getUsers(1, filterType, this.filterValue);
    },
  },
  watch: {
    "$store.state.Rawdata.current_active_module": function (o) {
      if (o) {
        this.getUsers(1);
      }
    },
    // page: function (ob) {
    //   this.getUsers(ob);
    // },
    filterName: function(filterName){
      let view = this
      view.$utils.setCookies('manage_users_username', filterName, view)
    },
    filterValue: function(filterValue){
      let view = this
      filterValue = filterValue == null ? '' : filterValue
      view.$utils.setCookies('manage_users_filterValue', filterValue, view)
    }
  },
};
</script>
